import React, { useLayoutEffect } from "react";

import { setFeatureFlag } from "@/app/actions.mjs";
import VideoAdTestPageRefs from "@/app/VideoAdTestPage.refs.jsx";
import { useReRender } from "@/shared/EventedRender.jsx";
import useDOMElement from "@/util/use-dom-element.mjs";

const VideoAdTest = () => {
  const reactAdElement = useDOMElement(VideoAdTestPageRefs.videoAd);
  useReRender();

  useLayoutEffect(() => {
    setFeatureFlag("ads", true);
  }, []);

  return <div>{reactAdElement}</div>;
};

export default VideoAdTest;

export function meta() {
  return {
    title: [null, "Video Ad Test"],
    description: [null, "Video Ad Test"],
  };
}
